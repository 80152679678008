import {
  CHECKOUT_LOADER,
  CLEAR_USER_CART_DATA,
  CLEAR_USER_DATA,
  GET_USER_CART_DATA,
  HEADER_CART_MENU_TOGGLE,
  SET_USER_DATA,
} from "../constants";

const headerInitialState = {
  cart_menu_toggle: false,
};

export const headerReducer = (state = headerInitialState, action) => {
  switch (action.type) {
    case HEADER_CART_MENU_TOGGLE:
      return {
        ...state,
        cart_menu_toggle: !state.cart_menu_toggle,
      };

    default:
      return state;
  }
};

const userInitialState = {
  userData: null,
};

export const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.data,
      };
    case CLEAR_USER_DATA:
      return {
        ...state,
        userData: null,
      };
    default:
      return state;
  }
};

const cartInitialState = {
  cartData: null,
  isCheckoutPageLoader: false,
};

export const cartReducer = (state = cartInitialState, action) => {
  switch (action.type) {
    case GET_USER_CART_DATA:
      return {
        ...state,
        cartData: action.data,
      };
    case CLEAR_USER_CART_DATA:
      return {
        ...state,
        cartData: null,
      };
    case CHECKOUT_LOADER:
      return {
        ...state,
        isCheckoutPageLoader: action.data,
      };
    default:
      return state;
  }
};
