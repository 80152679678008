import React from "react";

export default function InnerPageHeading({ heading }) {
  return (
    <>
      <section className="inner-page-heading">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>{heading && heading}</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
