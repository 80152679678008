import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

export default function NotificationMessage({ type, icon, message, redicate }) {
  const [isLoader, setIsLoader] = useState(false);

  function loaderHandler() {
    setTimeout(() => {
      setIsLoader(true);
    }, 5000);
  }

  useEffect(() => {
    if (redicate) {
      loaderHandler();
    }
  }, [type, icon, message, redicate]);

  return (
    <div className="row justify-content-center">
      <div className="col-sm-8 col-md-7 col-lg-6 col-xl-5 col-xxl-4">
        <div
          className={`form-box d-flex flex-column align-items-center text-center gap-4 form-box_${
            type && type ? type : "default"
          }`}
        >
          {!isLoader ? (
            <>
              <div className="form-box-icon">
                {icon && icon ? icon : <i className="fa-solid fa-question"></i>}
              </div>
              <h1 className="h3 mb-0">
                {message && message ? message : "No message"}
              </h1>
            </>
          ) : (
            <>
              <div className="form-box-icon unset">
                <Spinner animation="border" size="lg" />
              </div>
              <h1 className="h3 mb-0">Redirecting...</h1>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
