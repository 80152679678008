import { toast } from "react-toastify";

export const Helper = {
  handleApiError: async (error) => {
    if (error.code === "ERR_NETWORK") {
      toast.error(error.message, {
        icon: <i className="fa-solid fa-triangle-exclamation"></i>,
      });
    } else if (error.response && error.response.data) {
      const resp = error.response.data;
      console.log("resp", error.response);

      toast.warning(resp.message, {
        icon: <i className="fa-solid fa-triangle-exclamation"></i>,
      });
    }
  },
  nameSorter: function (name, length = 5) {
    const validLength = Math.max(0, Math.floor(length));

    if (name.length > validLength) {
      return name.slice(0, validLength) + "...";
    }

    return name;
  },
  DateFormat: function (date) {
    const createdAtDate = new Date(date);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };

    return createdAtDate.toLocaleDateString("en-US", options);
  },
};
