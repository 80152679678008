import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserCartData } from "../../redux-service/actions/actions";
import { AuthService } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { CartService } from "../../services/CartService";
import { toast } from "react-toastify";
import { constants } from "../../Constants";

export default function QtyPlusAndMinus({ value }) {
  const navigate = useNavigate();

  const [qty, setQty] = useState(1);
  const user = AuthService.GetUserAuth();
  const userID = user?.user?._id;

  const dispatch = useDispatch();

  async function QtyPlusAndMinusHandler(qty) {
    if (!userID) {
      navigate("/user/login");
      return;
    }

    if (!qty) {
      return;
    }

    const payload = {
      cart_product_qty: qty,
    };

    try {
      const getData = await new CartService().ItemQtyPlusAndMinus(
        value.cartId,
        payload
      );
      const result = getData?.data;
      if (result) {
        toast.success(result.message, {
          icon: <i className="fa-solid fa-check"></i>,
        });
        if (userID) {
          dispatch(getUserCartData(userID));
        }
      }
    } catch (error) {
      console.error(
        `${constants?.messages?.errors?.qtyPlusAndMinusToCart} ${error}`
      );
    }
  }

  useEffect(() => {
    setQty(value?.cartQty || 1);
  }, []);

  return (
    <>
      <div className="qty-plus-minus-btn">
        <button
          type="button"
          onClick={() => {
            if (qty > 1) {
              setQty(qty - 1);
              QtyPlusAndMinusHandler(qty - 1);
            }
          }}
        >
          <i className="fa-solid fa-minus"></i>
        </button>
        <span>{qty}</span>
        <button
          type="button"
          onClick={() => {
            if (value.stock_qty > qty) {
              setQty(qty + 1);
              QtyPlusAndMinusHandler(qty + 1);
            } else {
              toast.warning(`Only ${value.stock_qty} in stock.`, {
                icon: <i className="fa-solid fa-triangle-exclamation"></i>,
              });
            }
          }}
        >
          <i className="fa-solid fa-plus"></i>
        </button>
      </div>
    </>
  );
}
