import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import MainIndex from "./main/MainIndex";
import { rootReducers } from "./main/redux-service/reducers";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage"; // Default storage
import { thunk } from "redux-thunk";

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
};

// Wrap the root reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducers);

// Create the Redux store with thunk middleware
const store = createStore(persistedReducer, applyMiddleware(thunk));

// Create the persistor
export const persistor = persistStore(store);

// Render the app
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MainIndex />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// Web vitals for performance
reportWebVitals();
