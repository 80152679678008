import React, { useState } from "react";
import InnerPageHeading from "../component/pages/InnerPageHeading";
import { useForm } from "react-hook-form";
import { constants } from "../Constants";
import { FormService } from "../services/FormService";
import { toast } from "react-toastify";

export default function ContactUs() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  async function formHandler(data) {
    try {
      const payload = {
        contact_name: data.contact_name,
        contact_email: data.contact_email,
        contact_phone: data.contact_phone,
        contact_message: data.contact_message,
      };

      const response = await new FormService().ContactUsFormSubmit(payload);

      if (response.data.success) {
        toast.success(response?.data?.message, {
          icon: <i className="fa-solid fa-paper-plane"></i>,
        });
        reset();
      } else {
        toast.warning(response?.data?.message, {
          icon: <i className="fa-solid fa-triangle-exclamation"></i>,
        });
      }
    } catch (error) {
      console.error(`${constants.messages.errors.form} ${error.message}`);
    }
  }

  return (
    <>
      <InnerPageHeading heading="Contact Us" />
      <section className="section-padding p-last-0">
        <div className="container">
          <div className="row gy-4 gy-lg-0">
            <div className="col-lg-4">
              <div className="card card-contact">
                <p>
                  <b>Potter Pump Pvt. Ltd.</b>
                </p>
                <p>
                  shad No-1, Jeet industrial Area, Plot No: 211, Swati Park Main
                  Road, opposite New, Swati Park, Rajkot, Kotharia, Gujarat
                  360004
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card card-contact">
                <i className="fa-solid fa-phone"></i>
                <p>
                  <a href="tel:+916351298021">+91 63512 98021</a>
                </p>
                <p>
                  <a href="tel:+919913084962">+91 99130 84962</a>
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card card-contact">
                <i className="fa-solid fa-envelope"></i>
                <p>
                  <a href="mailto:potterpump.motor@gmail.com">
                    potterpump.motor@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding p-last-0 pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4">
              <h2>Get in Touch with Us</h2>
            </div>
            <div className="col-12">
              <form onSubmit={handleSubmit(formHandler)}>
                <div className="row gy-4">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="contact_name">Name</label>
                      {errors.contact_name && (
                        <span className="error-message">
                          {errors.contact_name.message}
                        </span>
                      )}
                      <input
                        type="text"
                        className="form-control"
                        id="contact_name"
                        {...register("contact_name", {
                          required: "Name is required",
                        })}
                        aria-invalid={errors.contact_name ? "true" : "false"}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="contact_email">Email</label>
                      {errors.contact_email && (
                        <span className="error-message">
                          {errors.contact_email.message}
                        </span>
                      )}
                      <input
                        type="text"
                        className="form-control"
                        id="contact_email"
                        {...register("contact_email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        aria-invalid={errors.contact_email ? "true" : "false"}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="contact_phone">Phone</label>
                      {errors.contact_phone && (
                        <span className="error-message">
                          {errors.contact_phone.message}
                        </span>
                      )}
                      <input
                        type="text"
                        className="form-control"
                        id="contact_phone"
                        {...register("contact_phone", {
                          required: "Phone is required",
                        })}
                        aria-invalid={errors.contact_phone ? "true" : "false"}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="contact_message">Message</label>
                      {errors.contact_message && (
                        <span className="error-message">
                          {errors.contact_message.message}
                        </span>
                      )}
                      <textarea
                        name="contact_message"
                        className="form-control"
                        id="contact_message"
                        rows={4}
                        {...register("contact_message", {
                          required: "Message is required",
                        })}
                        aria-invalid={errors.contact_message ? "true" : "false"}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-blue">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
