import axios from "../config/NodeAxios";
import { Helper } from "../component/core/Helper";

export class ShippingService {
  constructor() {
    this.endpoints = {
      BILLING_DETAILS: "/shipping/billing-details/:id",
    };

    this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  async CustomerBillingDetails(id, payload) {
    try {
      return await axios.put(
        this.endpoints.BILLING_DETAILS.replace(":id", id),
        payload,
        this.config
      );
    } catch (error) {
      Helper.handleApiError(error);
    }
  }
}
