import React from "react";
import Skeleton from "react-loading-skeleton";

export default function OrderSkeleton() {
  return (
    <>
      <div className="card card-order">
        <div className="card-head" style={{ height: "60px" }}></div>

        <div className="card-body">
          <div className="row gx-lg-5 gy-4">
            <div className="col-lg-6">
              <div className="grid cart-itme-summary">
                <Skeleton count={6} />
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-end align-items-start">
              <Skeleton
                style={{ borderRadius: "5px", width: "200px", height: "40px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
