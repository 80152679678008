import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomerService } from "../../services/CustomerService";
import { toast } from "react-toastify";
import { constants } from "../../Constants";

export default function ForgetPassword() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  async function formHandler(data) {
    try {
      const response = await new CustomerService().CustomerForgetPassword({
        customer_email: data.customer_email,
      });

      if (response?.data?.success) {
        toast.success(response?.data?.message, {
          icon: <i className="fa-solid fa-paper-plane"></i>,
        });
        reset();
        navigate("/user/login");
      } else {
        toast.warning(response?.data?.message, {
          icon: <i className="fa-solid fa-triangle-exclamation"></i>,
        });
      }
    } catch (error) {
      console.error(`${constants.messages.errors.forgetPass} ${error.message}`);
    }
  }

  return (
    <div className="row justify-content-center">
      <div className="col-lg-4">
        <div className="card user-card-1">
          <form onSubmit={handleSubmit(formHandler)}>
            <div className="row gy-4">
              <div className="col-12">
                <h1>Forget Password</h1>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="customer_email">Email</label>
                  {errors.customer_email && (
                    <span className="error-message">
                      {errors.customer_email.message}
                    </span>
                  )}
                  <input
                    type="text"
                    className="form-control"
                    id="customer_email"
                    {...register("customer_email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid email address",
                      },
                    })}
                    aria-invalid={errors.customer_email ? "true" : "false"}
                  />
                </div>
              </div>
              <div className="col-12 d-flex flex-column gap-3 mt-4">
                <button type="submit" className="btn btn-blue">
                  Request Reset Link
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
