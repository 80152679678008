import React, { useEffect } from "react";
import NotificationMessage from "./NotificationMessage";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isActiveCheckoutLoader } from "../../redux-service/actions/actions";

export default function PaymentSuccessMessage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 6500);

    dispatch(isActiveCheckoutLoader(false));
  }, []);

  return (
    <>
      <NotificationMessage
        type="success"
        icon={<i className="fa-solid fa-check"></i>}
        message="Payment successful! Your order has been placed successfully."
        redicate={true}
      />
    </>
  );
}
