import React, { useEffect, useState } from "react";
import InnerPageHeading from "../../component/pages/InnerPageHeading";
import ProductCart1 from "../../component/card/ProductCart1";
import Pagination from "@mui/material/Pagination";
import { ProductService } from "../../services/ProductService";
import { constants } from "../../Constants";
import { useSearchParams } from "react-router-dom";

export default function Products() {
  const [productData, setProductData] = useState([]);
  const [param, setParam] = useSearchParams();

  const [totalPages, setTotalPages] = useState(1);

  const getPage = Number(param.get("page") || 1);
  const getSearch = param.get("search");

  async function fetchProductData() {
    try {
      let query = `?page=${getPage}&limit=${constants.pagiLimit}`;

      if (getSearch) {
        query += `&search=${getSearch}`;
      }

      const response = await new ProductService().ProductLists(query);

      setProductData(response.data.result);
      const pagiTotalPage = response.data.paginationInfo.totalPages;

      setTotalPages(pagiTotalPage);

      if (getPage > pagiTotalPage) {
        param.set("page", 1);
        setParam(param);
      }

     if(!getSearch){
      document.documentElement.scrollTo({
        top: 250,
        left: 0,
        behavior: "smooth",
      });
     }
    } catch (error) {
      console.error(`${constants.messages.errors.read} ${error.message}`);
    }
  }

  function pagiHandler(e, page) {
    param.set("page", page);
    setParam(param);
  }

  useEffect(() => {
    fetchProductData();
  }, [getPage, getSearch]);

  return (
    <>
      <InnerPageHeading heading="Our Products" />
      <section className="section-padding">
        <div className="container">
          <div className="row gy-5">
            {productData &&
              productData.length > 0 &&
              productData.map((value, index) => (
                <div className="col-md-6 col-lg-3" key={`product_${value._id}`}>
                  <ProductCart1 value={value} />
                </div>
              ))}
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <Pagination
                count={totalPages}
                page={getPage}
                size="large"
                onChange={pagiHandler}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
