import React, { useEffect, useState } from "react";
import InnerPageHeading from "../../component/pages/InnerPageHeading";
import { constants } from "../../Constants";
import { OrderService } from "../../services/OrderService";
import { AuthService } from "../../services/AuthService";
import OrderCard from "../../component/card/OrderCard";

export default function Order() {
  const [orders, setOrders] = useState([]);

  const getUser = AuthService.GetUserAuth();
  const userId = getUser && getUser.user._id;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await new OrderService().GetOrderOfCustomer(userId);

        if (response.data) {
          setOrders(response.data.result);
        }
      } catch (error) {
        console.error(`${constants.messages.errors.read} ${error.message}`);
      }
    }

    fetchData();
  }, [userId]);

  return (
    <>
      <InnerPageHeading heading="Your Orders" />
      <section className="py-5">
        {orders &&
          orders.length > 0 &&
          orders.map((order, index) => <OrderCard value={order} />)}
      </section>
    </>
  );
}
