import axios from "../config/NodeAxios";
import { Helper } from "../component/core/Helper";

export class FormService {
  constructor() {
    this.endpoints = {
      CONTACT_US: "/website-forms/contact-us",
    };

    this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  async ContactUsFormSubmit(payload) {
    try {
      return await axios.post(this.endpoints.CONTACT_US, payload, this.config);
    } catch (error) {
      Helper.handleApiError(error);
    }
  }
}
