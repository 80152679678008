import axios from "./config/NodeAxios";
import { AuthService } from "./services/AuthService";
import { CustomerService } from "./services/CustomerService";

function runInterceptor() {
  axios.interceptors.request.use(
    function (config) {
      const getUser = AuthService.GetUserAuth();
      const authorizationToken = AuthService.GetAuthToken();
      if (authorizationToken) {
        config.headers = {
          ...config.headers,
          authorization: `${authorizationToken}`,
        };
      }

      if (getUser) {
        let requestData = config.data;

        if (requestData instanceof FormData) {
          if (!requestData.has("currentUserId")) {
            requestData.append("currentUserId", getUser?.user?._id);
          }
        } else {
          if (!requestData || !requestData.currentUserId) {
            requestData = {
              ...requestData,
              currentUserId: getUser?.user?._id,
            };
          }
        }

        config.data = requestData;

        let Requestby = encodeURIComponent(
          btoa(
            `${getUser.user?.customer_name || ""} (${
              getUser.user?.customer_email || ""
            })`
          )
        );

        let Requesturl = encodeURIComponent(btoa(getCurrentUrl()));

        // send user info to the server as request header
        config.headers = {
          ...config.headers,
          Requestby,
          Requesturl,
        };
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  let isRefreshing = false;
  let refreshPromise = null;

  axios.interceptors.response.use(
    function (response) {
      return response;
    },

    async function (error) {
      if (error?.response?.status === 403) {
        const originalRequest = error.config;

        if (!isRefreshing) {
          isRefreshing = true;

          // Create a new promise to handle the asynchronous token refresh
          refreshPromise = new Promise((resolve, reject) => {
            const user = AuthService.GetUserAuth();
            if (user) {
              new CustomerService()
                .CustomerTokenRefresh(user.user._id)
                .then((response) => {
                  if (response?.data?.success) {
                    AuthService.RefreshToken(response?.data?.result);
                    resolve(response?.data?.result?.token);
                  } else {
                    reject();
                  }
                })
                .catch((error) => {
                  reject(error);
                })
                .finally(() => {
                  isRefreshing = false;
                });
            }
          });

          // Background refresh
          try {
            const token_1 = await refreshPromise;
            // Retry the original request with the new token
            originalRequest.headers["authorization"] = `${token_1}`;
            return await axios(originalRequest);
          } catch {
            return await Promise.reject(error);
          }
        }
      }

      return Promise.reject(error);
    }
  );
}

function getCurrentUrl() {
  const pathname = window.location.pathname;
  return pathname;
}

export default runInterceptor;
