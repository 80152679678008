import axios from "../config/NodeAxios";
import { Helper } from "../component/core/Helper";

export class OrderService {
  constructor() {
    this.endpoints = {
      CREATE: "/orders/create",
      GET_ORDER_OF_CUSTOMER: "/orders/list/:customer_id",
    };

    this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  async CreateOrder(payload) {
    try {
      return await axios.post(this.endpoints.CREATE, payload, this.config);
    } catch (error) {
      Helper.handleApiError(error);
    }
  }

  async GetOrderOfCustomer(id) {
    try {
      return await axios.get(
        this.endpoints.GET_ORDER_OF_CUSTOMER.replace(":customer_id", id)
      );
    } catch (error) {
      Helper.handleApiError(error);
    }
  }
}
