export const AuthService = {
  GetUserAuth: function () {
    let user = localStorage.getItem("user_auth");
    if (user) {
      user = JSON.parse(user);
      return user;
    }
    return null;
  },
  isAuthenticated: function () {
    let user = localStorage.getItem("user_auth");
    if (user) {
      return true;
    }
    return false;
  },
  GetAuthToken() {
    const user = localStorage.getItem("user_auth");

    if (user) {
      try {
        const parsedUser = JSON.parse(user);
        return parsedUser.token || null;
      } catch (error) {
        console.error("Error parsing user auth data:", error);
        return null;
      }
    }

    return null;
  },
  SaveLogin(userData, _) {
    localStorage.setItem("user_auth", JSON.stringify(userData));
    window.location.href = "/";
  },
  RefreshToken(userData) {
    localStorage.setItem("user_auth", JSON.stringify(userData));
    window.location.reload();
  },
  UserLogout() {
    localStorage.removeItem("user_auth");
    window.location.href = "/user/login";
  },

  // authRequired === 1: Allow access if logged in; redirect if already logged in.
  // authRequired === 2: Restrict access if logged in; allow only if not logged in.
  // authRequired === 0: No authentication required; accessible to all users.
};
