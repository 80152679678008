import React, { useEffect, useState } from "react";
import { constants } from "../../Constants";
import { NavLink, useSearchParams } from "react-router-dom";
import ProductSkeleton from "../skeleton/ProductSkeleton";
import CartItmeSummaryCard from "./CartItmeSummaryCard";
import { Helper } from "../core/Helper";
import OrderSkeleton from "../skeleton/OrderSkeleton";

export default function OrderCard({ value }) {
  const [order, setOrder] = useState(null);
  const [viewOrder, setViewOrder] = useState({
    status: false,
    order_id: null,
  });

  const [searchParam] = useSearchParams();
  const getParamOrderId = searchParam.get("order_id");

  const viewOrderHandler = () => {
    setViewOrder({
      status: !viewOrder.status,
      order_id: order?.order_id,
    });
  };

  useEffect(() => {
    setTimeout(() => setOrder(value), 500);
  }, [value]);

  const shippingDetails = order?.order_shipping?.shipment_address;

  return (
    <div
      key={`order_${order?.order_id}`}
      id={order?.order_id}
      style={{
        backgroundColor: order?.order_id === getParamOrderId ? "#f5f5f5" : "",
      }}
    >
      <div className="container my-lg-4 py-4">
        {order ? (
          <div className="card card-order">
            <div className="card-head">
              <div className="row gy-3">
                <div className="col-lg-6">
                  <ul>
                    <li>
                      <span>Order placed</span>
                      <span>{Helper.DateFormat(order?.createdAt)}</span>
                    </li>
                    <li>
                      <span>Total</span>
                      <span>
                        {constants.currency_symbol} {order?.order_charges?.total}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 d-flex justify-content-lg-end">
                  <ul>
                    <li>
                      <span>Order #{order?.order_id}</span>
                      <span
                        className={
                          viewOrder.status && viewOrder.order_id === order?.order_id
                            ? "text-danger"
                            : "text-blue"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={viewOrderHandler}
                      >
                        {viewOrder.status && viewOrder.order_id === order?.order_id
                          ? "Hide"
                          : "View"}{" "}
                        order details
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {viewOrder.status && viewOrder.order_id === order?.order_id && (
              <div className="card-body">
                <div className="row gx-lg-5 gy-4">
                  <div className="col-lg-3">
                    <p className="mb-1 mb-lg-2">
                      <b>Shipping Address:</b>
                    </p>
                    <address className="mb-0">
                      {order?.cart_customer?.customer_name},{" "}
                      {shippingDetails?.street_address}, {shippingDetails?.city}
                      , {shippingDetails?.country}, {shippingDetails?.postcode}
                    </address>
                  </div>
                  <div className="col-lg-3">
                    <p className="mb-1 mb-lg-2">
                      <b>Order Status:</b>
                    </p>
                    <p className="mb-3">{order.status}</p>
                    <p className="mb-1 mb-lg-2">
                      <b>Shipping Status:</b>
                    </p>
                    <p className="mb-0">{order?.order_shipping?.status}</p>
                  </div>
                  <div className="col-lg-3">
                    <p className="mb-1 mb-lg-2">
                      <b>Payment Methods:</b>
                    </p>
                    <p className="mb-4">{order?.customer_payment?.type}</p>
                    <p className="mb-1 mb-lg-2">
                      <b>Payment Status:</b>
                    </p>
                    <p className="mb-3">{order?.customer_payment?.status}</p>
                    <p className="mb-1 mb-lg-2">
                      <b>Payment ID:</b>
                    </p>
                    <p className="mb-3">
                      {
                        order?.customer_payment?.payment_success_response
                          ?.razorpay_payment_id
                      }
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <p className="mb-1 mb-lg-2">
                      <b>Order Summary:</b>
                    </p>
                    <table className="order-summary">
                      <tbody>
                        <tr>
                          <td>Item(s) Subtotal:</td>
                          <td>
                            {constants.currency_symbol}{" "}
                            {order?.order_charges?.subTotal}
                          </td>
                        </tr>
                        <tr>
                          <td>Grand Total:</td>
                          <th>
                            {constants.currency_symbol} {order?.order_charges?.total}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            <div className="card-body">
              <div className="row gx-lg-5 gy-4">
                <div className="col-lg-6">
                  <div className="grid cart-itme-summary">
                    {order?.customer_cart_list?.length > 0 &&
                      order?.customer_cart_list.map((item, index) => (
                        <div className="grid-item" key={index}>
                          <CartItmeSummaryCard value={item} />
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-end align-items-start">
                  <NavLink to="/contact-us" className="btn btn-warning">
                    Get product support
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <OrderSkeleton />
        )}
      </div>
    </div>
  );
}
