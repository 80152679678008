import React from "react";
import { IMAGE_BASE_URL } from "../../config/Config";
import RazorpayBtn from "../../component/button/RazorpayBtn";

export default function PaymentInformation() {
  return (
    <>
      <h2 className="h4 mb-4">Payment Information</h2>
      <div className="payment-information-box">
        <div className="d-flex flex-column gap-4 align-items-center">
          <img
            src={`${IMAGE_BASE_URL}/razorpay_logo.svg`}
            alt="..."
            width="250px"
          />
          <RazorpayBtn />
        </div>
      </div>
    </>
  );
}
