import axios from "../config/NodeAxios";
import { Helper } from "../component/core/Helper";

export class ProductService {
  constructor() {
    this.endpoints = {
      LIST: "/products/public/list",
      SHOW: "/products/public/show/:id?gst=true",
    };
  }

  async ProductLists(query) {
    try {
      let url;

      if (query) {
        url = this.endpoints.LIST + query;
      } else {
        url = this.endpoints.LIST;
      }

      const response = await axios.get(url);
      return response;
    } catch (error) {
      Helper.handleApiError(error);
    }
  }

  async GetProductDetails(id) {
    try {
      return await axios.get(
        this.endpoints.SHOW.replace(":id", id),
        this.token_config
      );
    } catch (error) {
      Helper.handleApiError(error);
    }
  }
}
