import { AuthService } from "./AuthService";
import axios from "../config/NodeAxios";
import { Helper } from "../component/core/Helper";

export class CartService {
  constructor() {
    this.authorizationToken = AuthService.GetAuthToken();

    this.endpoints = {
      CUSTOMER_CART_LIST: "/carts/list/:id",
      ADD_TO_CART: "/carts/add-to-cart",
      REMOVE_TO_CART: "/carts/remove-to-cart/:id",
      QTY_ADD_AND_MINUS: "/carts/qty-plus-minus/:id",
    };

    this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    this.token_config = {
      headers: {
        authorization: `${this.authorizationToken}`,
      },
    };
  }

  async GetCustomerCartList(id) {
    try {
      return await axios.get(
        this.endpoints.CUSTOMER_CART_LIST.replace(":id", id),
        this.token_config
      );
    } catch (error) {
      Helper.handleApiError(error);
    }
  }

  async ItemAddToCart(payload) {
    try {
      return await axios.post(this.endpoints.ADD_TO_CART, payload, this.config);
    } catch (error) {
      Helper.handleApiError(error);
    }
  }

  async ItemRemoveToCart(id) {
    try {
      return await axios.delete(
        this.endpoints.REMOVE_TO_CART.replace(":id", id)
      );
    } catch (error) {
      Helper.handleApiError(error);
    }
  }

  async ItemQtyPlusAndMinus(id, payload) {
    try {
      return await axios.post(
        this.endpoints.QTY_ADD_AND_MINUS.replace(":id", id),
        payload,
        this.config
      );
    } catch (error) {
      Helper.handleApiError(error);
    }
  }
}
