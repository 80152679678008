import React, { useEffect, useState } from "react";
import InnerPageHeading from "../../component/pages/InnerPageHeading";
import BillingDetails from "./BillingDetails";
import OrderSummary from "./OrderSummary";
import PaymentInformation from "./PaymentInformation";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CheckoutLoader from "../../component/message-boxs/CheckoutLoader";

export default function Checkout() {
  // const [isPageLoading, setIsPageLoading] = useState(true);

  const cartData = useSelector((state) => state.cartReducer.cartData);
  const isPageLoading = useSelector((state) => state.cartReducer.isCheckoutPageLoader);

  // useEffect(() => {
  //   const handleWindowLoad = () => {
  //     setIsPageLoading(false);
  //   };

  //   window.addEventListener("load", handleWindowLoad);

  //   return () => {
  //     window.removeEventListener("load", handleWindowLoad);
  //   };
  // }, []);

  if (cartData && cartData.length === 0) {
    return <Navigate to="/" />;
  }

  // if (isPageLoading) {
  //   return <CheckoutLoader />;
  // }

  return (
    <>
      <InnerPageHeading heading="Checkout" />
      <section className="section-padding">
        <div className="container">
          <div className="row gy-5 gx-lg-5">
            <div className="col-lg-8">
              <h2 className="mb-4">Billing details</h2>
              <BillingDetails />
            </div>
            <div className="col-lg-4">
              <OrderSummary />
              <PaymentInformation />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
