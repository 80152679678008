import React, { useEffect, useState } from "react";
import { constants } from "../../Constants";
import { useForm } from "react-hook-form";
import { ShippingService } from "../../services/ShippingService";
import { AuthService } from "../../services/AuthService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setUserDataAction } from "../../redux-service/actions/actions";

export default function BillingDetails() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const user = useSelector((state) => state.userReducer.userData);
  const userID = user && user?._id;

  const [inputData, setInputData] = useState({
    name: "",
    company_name: "",
    country: "",
    street_address: "",
    city: "",
    postcode: "",
    phone: "",
    email_address: "",
  });

  const {
    name,
    company_name,
    country,
    street_address,
    city,
    postcode,
    phone,
    email_address,
  } = inputData;

  const dispatch = useDispatch();

  async function formHandler(data) {
    try {
      const payload = {
        name: data.name,
        company_name: data.company_name,
        country: data.country,
        street_address: data.street_address,
        city: data.city,
        postcode: data.postcode,
        phone: data.phone,
        email_address: data.email_address,
      };

      const response = await new ShippingService().CustomerBillingDetails(
        userID,
        payload
      );

      if (response.data) {
        toast.success(response?.data?.message, {
          icon: <i className="fa-solid fa-pencil"></i>,
        });
        dispatch(setUserDataAction(userID));
      }
    } catch (error) {
      console.error(
        `${constants.messages.errors.shippingDetails} ${error.message}`
      );
    }
  }

  useEffect(() => {
    if(user){
      if (user?.customer_shipping_details) {
        setInputData({
          ...inputData,
          name: user.customer_shipping_details.name,
          company_name: user.customer_shipping_details.company_name,
          country: user.customer_shipping_details.country,
          street_address: user.customer_shipping_details.street_address,
          city: user.customer_shipping_details.city,
          postcode: user.customer_shipping_details.postcode,
          phone: user.customer_shipping_details.phone,
          email_address: user.customer_shipping_details.email_address,
        });
  
        setValue("name", user.customer_shipping_details.name);
        setValue("company_name", user.customer_shipping_details.company_name);
        setValue("country", user.customer_shipping_details.country);
        setValue("street_address", user.customer_shipping_details.street_address);
        setValue("city", user.customer_shipping_details.city);
        setValue("postcode", user.customer_shipping_details.postcode);
        setValue("phone", user.customer_shipping_details.phone);
        setValue("email_address", user.customer_shipping_details.email_address);
      } else {
        setInputData({
          ...inputData,
          name: user.customer_name,
          phone: user.customer_phone,
          email_address: user.customer_email,
        });
  
        setValue("name", user.customer_name);
        setValue("phone", user.customer_phone);
        setValue("email_address", user.customer_email);
      }
    }
  }, []);

  return (
    <>
      <form className="row gy-4" onSubmit={handleSubmit(formHandler)}>
        <div className="col-lg-6 form-group">
          <label htmlFor="name">Name</label>
          {errors.name && (
            <span className="error-message">{errors.name.message}</span>
          )}
          <input
            type="text"
            id="name"
            className="form-control"
            defaultValue={name}
            {...register("name", {
              required: "Name is required",
            })}
            aria-invalid={errors.name ? "true" : "false"}
          />
        </div>
        <div className="col-lg-6 form-group">
          <label htmlFor="company_name">Company Name</label>
          {errors.company_name && (
            <span className="error-message">{errors.company_name.message}</span>
          )}
          <input
            type="text"
            id="company_name"
            className="form-control"
            defaultValue={company_name}
            {...register("company_name", {
              required: "Company name is required",
            })}
            aria-invalid={errors.company_name ? "true" : "false"}
          />
        </div>
        <div className="col-lg-12 form-group">
          <label htmlFor="country">Country *</label>
          {errors.country && (
            <span className="error-message">{errors.country.message}</span>
          )}
          <div className="select-wrapper">
            <select
              name="country"
              id="country"
              className="form-control"
              defaultValue={country}
              {...register("country", {
                required: "Country is required",
              })}
              aria-invalid={errors.country ? "true" : "false"}
            >
              <option value="">---</option>
              {constants.countryList.map((value) => (
                <option value={value}>{value}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-lg-12 form-group">
          <label htmlFor="street_address">Street address *</label>
          {errors.street_address && (
            <span className="error-message">
              {errors.street_address.message}
            </span>
          )}
          <input
            type="text"
            id="street_address"
            className="form-control"
            defaultValue={street_address}
            {...register("street_address", {
              required: "Street address is required",
            })}
            aria-invalid={errors.street_address ? "true" : "false"}
          />
        </div>
        <div className="col-lg-12 form-group">
          <label htmlFor="city">Town / City *</label>
          {errors.city && (
            <span className="error-message">{errors.city.message}</span>
          )}
          <input
            type="text"
            id="city"
            className="form-control"
            defaultValue={city}
            {...register("city", {
              required: "City is required",
            })}
            aria-invalid={errors.city ? "true" : "false"}
          />
        </div>
        <div className="col-lg-12 form-group">
          <label htmlFor="postcode">Postcode </label>
          {errors.postcode && (
            <span className="error-message">{errors.postcode.message}</span>
          )}
          <input
            type="text"
            id="postcode"
            className="form-control"
            defaultValue={postcode}
            {...register("postcode", {
              required: "Postcode is required",
            })}
            aria-invalid={errors.postcode ? "true" : "false"}
          />
        </div>
        <div className="col-lg-6 form-group">
          <label htmlFor="phone">Phone </label>
          {errors.phone && (
            <span className="error-message">{errors.phone.message}</span>
          )}
          <input
            type="text"
            id="phone"
            className="form-control"
            defaultValue={phone}
            {...register("phone", {
              required: "Phone number is required",
            })}
            aria-invalid={errors.phone ? "true" : "false"}
          />
        </div>
        <div className="col-lg-6 form-group">
          <label htmlFor="email_address">Email address *</label>
          {errors.email_address && (
            <span className="error-message">
              {errors.email_address.message}
            </span>
          )}
          <input
            type="text"
            id="email_address"
            className="form-control"
            {...register("email_address", {
              required: "Email address is required",
            })}
            aria-invalid={errors.email_address ? "true" : "false"}
            disabled
          />
        </div>
        <div className="col-12 d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-black"
            disabled={user?.customer_shipping_details ? false : false}
          >
            {user?.customer_shipping_details ? "Update" : "Submit"}
          </button>
        </div>
      </form>
    </>
  );
}
