import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { constants } from "../../Constants";
import { CustomerService } from "../../services/CustomerService";
import { toast } from "react-toastify";
import { AuthService } from "../../services/AuthService";

export default function Login() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [inputData, setInputData] = useState({
    user_email: "",
    user_password: "",
  });

  const { user_email, user_password } = inputData;

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  async function formHandler(data) {
    try {
      const payload = {
        customer_email: data.user_email,
        customer_password: data.user_password,
      };

      const response = await new CustomerService().LoginCustomer(payload);

      if (response?.data?.status) {
        toast.success(response?.data?.message, {
          icon: <i className="fa-solid fa-user-check"></i>,
        });
        AuthService.SaveLogin(response?.data?.result);
      } else {
        toast.warning(response?.data?.message, {
          icon: <i className="fa-solid fa-triangle-exclamation"></i>,
        });
      }
    } catch (error) {
      console.error(`${constants.messages.errors.login} ${error.message}`);
    }
  }

  return (
    <div className="row justify-content-center">
      <div className="col-lg-4">
        <div className="card user-card-1">
          <form onSubmit={handleSubmit(formHandler)}>
            <div className="row gy-4">
              <div className="col-12">
                <h1>Login</h1>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="user_email">Email</label>
                  {errors.user_email && (
                    <span className="error-message">
                      {errors.user_email.message}
                    </span>
                  )}
                  <input
                    type="text"
                    className="form-control"
                    id="user_email"
                    {...register("user_email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid email address",
                      },
                    })}
                    aria-invalid={errors.user_email ? "true" : "false"}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="user_password">Password</label>
                  {errors.user_password && (
                    <span className="error-message">
                      {errors.user_password.message}
                    </span>
                  )}
                  <div className="password-input-wrapper">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      className="form-control"
                      id="user_password"
                      {...register("user_password", {
                        required: "Password is required",
                      })}
                      aria-invalid={errors.user_password ? "true" : "false"}
                    />
                    <div
                      className="icon"
                      onClick={() => {
                        setIsPasswordVisible(!isPasswordVisible);
                      }}
                    >
                      {isPasswordVisible ? (
                        <i className="fa-regular fa-eye-slash"></i>
                      ) : (
                        <i className="fa-regular fa-eye"></i>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-end">
                <NavLink to="/user/forget-password">Forget Password ?</NavLink>
              </div>
              <div className="col-12 d-flex flex-column gap-3 mt-4">
                <button type="submit" className="btn btn-blue">
                  Login
                </button>
                <div className="text-center">
                  <NavLink to="/user/register">Register</NavLink>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
