export const constants = {
  currency_symbol: "₹",
  pagiLimit: 9,
  messages: {
    errors: {
      create: "Error during creation operation:",
      shippingDetails: "Error during creation shipping details operation:",
      form: "Error during form submit operation:",
      update: "Error during update operation:",
      read: "Error during read operation:",
      login: "Error during login operation:",
      forgetPass: "Error during forget password operation:",
      resetPass: "Error during reset password operation:",
      changePass: "Error during change password operation:",
      delete: "Error during deletion  operation:",
      addToCart: "Error during add to cart operation:",
      qtyPlusAndMinusToCart: "Error during qty plus and minus cart operation:",
      removeToCart: "Error during remove to cart operation:",
    },
  },
  countryList: ["India"],
};
