import React from "react";
import { IMAGE_BASE_URL } from "../config/Config";
import InnerPageHeading from "../component/pages/InnerPageHeading";

export default function AboutUs() {
  return (
    <>
      <InnerPageHeading heading="About Us" />
      <section className="section-padding p-last-0">
        <div className="container">
          <div className="row gx-lg-5 flex-column-reverse flex-lg-row align-items-center">
            <div className="col-lg-6">
              <h2 className="heading-2 mb-3 mb-lg-4">About Potter Pump</h2>
              <p className="large-text-1">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla
                blanditiis velit corrupti accusamus natus cum?
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae
                doloremque ipsam nemo voluptatibus quo accusantium enim officia
                aperiam soluta expedita nobis voluptates, ipsa necessitatibus
                odit maiores incidunt ut! Vel, reiciendis!
              </p>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rerum
                enim in facere expedita earum error reprehenderit aspernatur
                sequi, accusamus, dolorem mollitia quis libero odit assumenda
                cumque? Debitis dolor repudiandae officiis!
              </p>
            </div>
            <div className="col-lg-6 mb-4 mb-0 d-flex justify-content-center">
              <img
                src={`${IMAGE_BASE_URL}/banner-img-1.png`}
                alt=""
                className="img-fluid hero-banner-img"
                width="450"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding bg-light">
        <div className="container">
          <div className="row gy-4 gy-lg-0">
            <div className="col-lg-4">
              <div className="card card-about-1">
                <img
                  src={`${IMAGE_BASE_URL}/target.svg`}
                  alt=""
                  className="img-fluid"
                />
                <h3>Mission</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Officia, quisquam rem sed voluptas non veritatis, aspernatur
                  dignissimos tenetur nostrum aliquid quos quidem. Quae, autem
                  labore!
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card card-about-1">
                <img
                  src={`${IMAGE_BASE_URL}/vision.svg`}
                  alt=""
                  className="img-fluid"
                />
                <h3>Vision</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Officia, quisquam rem sed voluptas non veritatis, aspernatur
                  dignissimos tenetur nostrum aliquid quos quidem. Quae, autem
                  labore!
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card card-about-1">
                <img
                  src={`${IMAGE_BASE_URL}/value.svg`}
                  alt=""
                  className="img-fluid"
                />
                <h3>Values</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Officia, quisquam rem sed voluptas non veritatis, aspernatur
                  dignissimos tenetur nostrum aliquid quos quidem. Quae, autem
                  labore!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding p-last-0">
        <div className="container">
          <div className="row gy-4 gy-lg-0 gx-lg-5 align-items-center">
            <div className="col-lg-6">
              <img
                src={`${IMAGE_BASE_URL}/about-img-2.jpg`}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6">
              <h2 className="heading-2 mb-3 mb-lg-4">Company Branding</h2>
              <p className="large-text-1">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Delectus placeat harum, corrupti totam ut enim?
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia
                error praesentium dignissimos perspiciatis sunt dolorem,
                architecto suscipit laboriosam? Similique enim fugiat,
                consequatur vero placeat iste, beatae deserunt vel nihil animi
                voluptates inventore voluptate mollitia officiis commodi? Eius
                facere voluptatem officiis quas atque dolore deleniti
                repudiandae ipsam, perspiciatis, unde quod ab?
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
