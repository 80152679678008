import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-loading-skeleton/dist/skeleton.css'
import "./assets/css/style.css";
import { BrowserRouter } from "react-router-dom";
import RouterRoot from "./RouterRoot";
import Header from "./component/header/Header";
import Footer from "./component/footer/Footer";
import ScrollTop from "./component/core/ScrollTop";
import { ToastContainer } from "react-toastify";

export default function MainIndex() {
  return (
    <BrowserRouter>
      <ScrollTop />
      <Header />
      <RouterRoot />
      <Footer />
      <ToastContainer
        position="top-left"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </BrowserRouter>
  );
}
