import { AuthService } from "./AuthService";
import axios from "../config/NodeAxios";
import { Helper } from "../component/core/Helper";

export class CustomerService {
  constructor() {
    this.authorizationToken = AuthService.GetAuthToken();

    this.endpoints = {
      LOGIN: "/customers/login",
      REGISTER: "/customers/register",
      UPDATE: "/customers/update/:id",
      SHOW: "/customers/show/:id",
      FORGET_PASSWORD: "/customers/forget-password",
      RESET_PASSWORD: "/customers/reset-password",
      REFRESH_TOKEN: "/customers/refresh_token/:id",
    };

    this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    this.config2 = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    this.token_config = {
      headers: {
        authorization: `${this.authorizationToken}`,
      },
    };
  }

  async LoginCustomer(payload) {
    try {
      return await axios.post(this.endpoints.LOGIN, payload, this.config);
    } catch (error) {
      Helper.handleApiError(error);
    }
  }

  async RegisterCustomer(payload) {
    try {
      return await axios.post(this.endpoints.REGISTER, payload, this.config2);
    } catch (error) {
      Helper.handleApiError(error);
    }
  }

  async UpdateCustomer(id, payload) {
    try {
      return await axios.put(
        this.endpoints.UPDATE.replace(":id", id),
        payload,
        this.config2
      );
    } catch (error) {
      Helper.handleApiError(error);
    }
  }

  async GetCustomerDetails(id) {
    try {
      return await axios.get(
        this.endpoints.SHOW.replace(":id", id),
        this.token_config
      );
    } catch (error) {
      console.error("error" + error.message);
      // Helper.handleApiError(error);
    }
  }

  async CustomerForgetPassword(payload) {
    try {
      return await axios.post(
        this.endpoints.FORGET_PASSWORD,
        payload,
        this.config
      );
    } catch (error) {
      Helper.handleApiError(error);
    }
  }

  async CustomerResetPassword(payload) {
    try {
      return await axios.post(
        this.endpoints.RESET_PASSWORD,
        payload,
        this.config
      );
    } catch (error) {
      Helper.handleApiError(error);
    }
  }

  async CustomerTokenRefresh(id) {
    try {
      return await axios.get(this.endpoints.REFRESH_TOKEN.replace(":id", id));
    } catch (error) {
      Helper.handleApiError(error);
    }
  }
}
