import React, { useRef } from "react";
import { IMAGE_BASE_URL, PUBLIC_IMAGE_PATH } from "../../config/Config";
import { toast } from "react-toastify";

export default function DealerDocumentUploadHandler({
  className,
  name = "Image",
  placeholderImg = "",
  imageValue = "",
  onResult = () => {},
}) {
  const documentInputRef = useRef();

  function handleFileUpload(e) {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

      if (file) {
        if (file.size <= maxFileSize) {
          onResult(file);
        } else {
          toast.error(
            "File size exceeds the 2MB limit. Please upload a smaller file."
          );
        }
      }
    }
  }

  return (
    <>
      <div className="col-12 d-flex gap-3 align-items-start">
        <input
          type="file"
          className="form-control"
          ref={documentInputRef}
          hidden
          onChange={handleFileUpload}
        />
        <div className={className ? className : "document-placeholder"}>
          <img
            src={
              imageValue
                ? typeof imageValue === "string"
                  ? `${PUBLIC_IMAGE_PATH}${imageValue}`
                  : URL.createObjectURL(imageValue)
                : `${IMAGE_BASE_URL}/${placeholderImg}`
            }
            alt={name}
            className="img-fluid"
          />
          {/* {imageValue !== "" && (
            <button
              type="button"
              className="btn btn-red btn-icon btn-sm close"
              onClick={() => onResult("")}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          )} */}
        </div>
        <div>
          <div className="d-flex gap-3 align-items-center mb-3">
            {imageValue !== "" && (
              <button
                type="button"
                className="btn btn-red btn-icon btn-sm close"
                onClick={() => onResult("")}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            )}
            <button
              type="button"
              className="btn btn-black btn-sm"
              onClick={() => {
                documentInputRef.current.click();
              }}
            >
              Upload {name}
            </button>
          </div>
          <p>Allowed PNG or JPEG. Max size of 2mb.</p>
        </div>
      </div>
    </>
  );
}
