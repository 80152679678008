import React from "react";
import { IMAGE_BASE_URL } from "../config/Config";

export default function PageNotFound() {
  return (
    <>
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <img
                src={`${IMAGE_BASE_URL}/404-page-not-found.png`}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
