import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserCartData,
  headerCartMenuToggle,
} from "../../redux-service/actions/actions";
import { AuthService } from "../../services/AuthService";
import { CartService } from "../../services/CartService";
import { toast } from "react-toastify";
import { constants } from "../../Constants";
import { useNavigate } from "react-router-dom";

export default function AddToCartBtn({ type, value }) {
  const navigate = useNavigate();

  const user = AuthService.GetUserAuth();
  const userID = user?.user?._id;

  const dispatch = useDispatch();

  async function AddToCartHandler() {
    if (!userID) {
      navigate("/user/login");
      return;
    }

    if (!value || !value.qty) {
      return;
    }

    const payload = {
      cart_customer_id: userID,
      cart_product_id: value?.cart_product_id,
      cart_product_qty: value.qty,
    };

    try {
      const getData = await new CartService().ItemAddToCart(payload);
      const result = getData?.data;
      if (result) {
        toast.success(result.message, {
          icon: <i className="fa-solid fa-plus"></i>,
        });
        if (userID) {
          dispatch(getUserCartData(userID));
          dispatch(headerCartMenuToggle());
        }
      }
    } catch (error) {
      console.error(`${constants?.messages?.errors?.addToCart} ${error}`);
    }
  }

  if (type === "icon") {
    return (
      <>
        <button
          type="button"
          className="btn btn-icon btn-blue"
          onClick={AddToCartHandler}
        >
          <i className="fa-solid fa-cart-plus"></i>
        </button>
      </>
    );
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-black"
        onClick={AddToCartHandler}
      >
        Add To Cart
      </button>
    </>
  );
}
