import { CartService } from "../../services/CartService";
import { CustomerService } from "../../services/CustomerService";
import {
  CHECKOUT_LOADER,
  CLEAR_USER_CART_DATA,
  CLEAR_USER_DATA,
  GET_USER_CART_DATA,
  HEADER_CART_MENU_TOGGLE,
  SET_USER_DATA,
} from "../constants";

export const headerCartMenuToggle = () => {
  return {
    type: HEADER_CART_MENU_TOGGLE,
  };
};

export const setUserDataAction = (id) => {
  return async (dispatch) => {
    try {
      const response = await new CustomerService().GetCustomerDetails(id);
      const result = response?.data?.result;
      if (result) {
        dispatch({
          type: SET_USER_DATA,
          data: result,
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };
};

export const ClearUserDataAction = () => {
  return {
    type: CLEAR_USER_DATA,
  };
};

export const getUserCartData = (id) => {
  if (!id)
    return {
      type: GET_USER_CART_DATA,
      data: [],
    };

  return async (dispatch) => {
    try {
      const response = await new CartService().GetCustomerCartList(id);
      const result = response?.data?.result;
      if (result) {
        dispatch({
          type: GET_USER_CART_DATA,
          data: result,
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };
};

export const ClearUserCartData = () => {
  return {
    type: CLEAR_USER_CART_DATA,
  };
};

export const isActiveCheckoutLoader = (data) => {
  return {
    type: CHECKOUT_LOADER,
    data: data,
  };
};
