import React, { useEffect, useState } from "react";
import { ProductService } from "../../services/ProductService";
import { constants } from "../../Constants";
import ProductCart1 from "../card/ProductCart1";

export default function HomeProductList() {
  const [productData, setProductData] = useState([]);

  async function fetchProductData() {
    try {
      const query = "?page=1&limit-4";

      const response = await new ProductService().ProductLists(query);

      setProductData(response.data.result);
    } catch (error) {
      console.error(`${constants.messages.errors.read} ${error.message}`);
    }
  }

  useEffect(() => {
    fetchProductData();
  }, []);
  
  return (
    <>
      <div className="product-card-grid">
        {productData &&
          productData.length > 0 &&
          productData.map((value, index) => (
            <div className="grid-item" key={`product_${value._id}`}>
              <ProductCart1 value={value} />
            </div>
          ))}
      </div>
    </>
  );
}
