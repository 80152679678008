import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { AuthService } from "../services/AuthService";
import { Navigate } from "react-router-dom";

export default function UserWrapper({ meta, cmp, authRequired }) {
  const Component = cmp;

  const isAuthenticated = AuthService.isAuthenticated();

  if (!isAuthenticated && authRequired === 1) {
    return <Navigate to="/user/login" />;
  } else if (isAuthenticated && authRequired === 2) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{meta ? meta.title : "Title not set"}</title>
        </Helmet>
        <div className="user-wrpaper">
          <div className="container">{Component && <Component />}</div>
        </div>
      </HelmetProvider>
    </>
  );
}
