import React from "react";
import { AuthService } from "../../services/AuthService";
import { useDispatch } from "react-redux";
import { CartService } from "../../services/CartService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserCartData } from "../../redux-service/actions/actions";
import { constants } from "../../Constants";

export default function RemoveToCartBtn({ value }) {
  const navigate = useNavigate();

  const user = AuthService.GetUserAuth();
  const userID = user?.user?._id;

  const dispatch = useDispatch();

  async function removeToCartHandler() {
    if (!userID) {
      navigate("/user/login");
      return;
    }

    try {
      const getData = await new CartService().ItemRemoveToCart(value.cartId);
      const result = getData?.data;
      if (result) {
        toast.error(result.message, {
          icon: <i className="fa-solid fa-trash"></i>,
        });
        if (userID) {
          dispatch(getUserCartData(userID));
        }
      }
    } catch (error) {
      console.error(`${constants?.messages?.errors?.removeToCart} ${error}`);
    }
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-red btn-icon btn-sm"
        onClick={removeToCartHandler}
      >
        <i className="fa-solid fa-xmark"></i>
      </button>
    </>
  );
}
