import axios from "../config/NodeAxios";
import { Helper } from "../component/core/Helper";

export class PaymentService {
  constructor() {
    this.endpoints = {
      GET_RAZORPAY_API_KEY: "/payment/razorpay/get-key",
      PAYMENT_VERIFICATION: "/payment/razorpay/paymentverification",
    };

    this.config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  async GetRazorpayApiKey() {
    try {
      return await axios.get(this.endpoints.GET_RAZORPAY_API_KEY);
    } catch (error) {
      Helper.handleApiError(error);
    }
  }
  RazorpayPaymentVerification() {
    return this.endpoints.PAYMENT_VERIFICATION;
  }
}
