import React from "react";
import { IMAGE_BASE_URL } from "../../config/Config";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function ProductSkeleton() {
  return (
    <>
      <div className="card product-card-1 card-dark">
        <div className="card-img">
          <img
            src={`${IMAGE_BASE_URL}/default-image-md.png`}
            className="img-fluid"
            alt="..."
          />
        </div>
        <div className="card-body">
          <div style={{ width: "100%" }}>
            <Skeleton style={{ width: "80%" }} />
            <Skeleton style={{ width: "100%" }} />
          </div>
          <div>
            <Skeleton
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
