import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helper } from "../core/Helper";
import { AuthService } from "../../services/AuthService";
import {
  ClearUserCartData,
  ClearUserDataAction,
} from "../../redux-service/actions/actions";
import { persistor } from "../../..";

export default function ProfileMenu() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserData = useSelector((state) => state.userReducer.userData);

  function logOutHandler() {
    handleClose();
    AuthService.UserLogout();
    dispatch(ClearUserDataAction());
    dispatch(ClearUserCartData());

    persistor.purge();
  }

  return (
    <>
      <li
        id="header-profile-button"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          width="36"
          height="36"
          fill="currentColor"
          id="person-icon"
          aria-haspopup="true"
        >
          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"></path>
        </svg>
        <div>
          <span>Login</span>
          <b>
            {getUserData && getUserData.customer_name
              ? Helper.nameSorter(getUserData.customer_name)
              : "Account"}
          </b>
        </div>
      </li>
      <Menu
        id="header-profile-menu"
        aria-labelledby="header-profile-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {getUserData ? (
          <>
            <MenuItem>Hi, {getUserData.customer_name}</MenuItem>
            <MenuItem onClick={handleClose}>
              <NavLink to="/user/profile/update">Profile</NavLink>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <NavLink to="/user/orders">Your Order</NavLink>
            </MenuItem>
            <MenuItem onClick={logOutHandler}>Logout</MenuItem>
          </>
        ) : (
          <>
            <MenuItem onClick={handleClose}>
              <NavLink to="/user/register">Register</NavLink>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <NavLink to="/user/login">Login</NavLink>
            </MenuItem>
          </>
        )}

        {/* <MenuItem onClick={handleClose}>
          <NavLink to="/user/order">Orders</NavLink>
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </>
  );
}
